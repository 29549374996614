import { defineStore } from 'pinia'

import type { PaymentCard } from '../types'

type StoreState = {
  hasPurchase: boolean
  subscriptionIds: string[]
  cards: PaymentCard[]
}

export const usePaymentStore = defineStore('payment', {
  state: (): StoreState => {
    return {
      hasPurchase: false,
      subscriptionIds: [],
      cards: [],
    }
  },
  actions: {},
})
